<template>
  <div class="admin-statistics-listing">
    <title-bar exportBtn title="Statistics" addUrl="/" />
    <div class="admin-statistics-listing-filter">
      <v-select
        v-model="selectedItem"
        :options="options"
        class="custom-select"
        :clearable="false"
        :searchable="false"
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <img src="@/assets/images/icons/filter-sort-down.svg" />
          </span>
        </template>
      </v-select>
      <flat-pickr
        v-model="date"
        :config="{
          mode: 'range',
          altFormat: 'd.m.Y',
          dateFormat: 'Y-m-d',
          altInput: true,
          altInputClass: 'flatpickr-input',
          locale: {
            rangeSeparator: ' - ',
          },
        }"
      ></flat-pickr>
    </div>
    <vue-good-table v-if="selectedItem === 'Orders'" class="custom-table-style" :columns="columns" :rows="rows">
      <template slot="table-row" slot-scope="props">
        <span :class="props.column.field" v-if="props.column.field == 'state'">
          <table-bullet :value="props.formattedRow[props.column.field]" />
        </span>
        <span v-else-if="props.column.field == 'courier'">
          <div :class="props.column.field">
            <img
              class="courier-photo"
              src="https://placekitten.com/300/300"
              alt="courier-photo"
            />
            <span>{{ props.formattedRow[props.column.field] }}</span>
          </div>
        </span>
        <span v-else-if="props.column.field == 'id'">
          <b-button
            v-b-toggle="`collapse-${props.formattedRow[props.column.field]}`"
            class="custom-btn custom-collapse"
          >
            <img src="@/assets/images/icons/arrow-up.svg" />
          </b-button>
          <div class="collapsible-content-row">
            <b-collapse
              :id="`collapse-${props.formattedRow[props.column.field]}`"
            >
              <div class="order-row">
                <!-- Info -->
                <div class="order-info">
                  <div class="order-info-flex">
                    <div class="name">1150 Braunhierschengasse 2</div>
                    <div class="place">
                      <span class="entrance">Entrance 8</span>
                      <span class="flat">Flat 4</span>
                      <span class="floor">2.floor</span>
                    </div>
                    <div class="number">
                      <span>+421 928 556 953</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Ordered items -->
              <div class="ordered-item">
                <div class="item">
                  <div class="amount">3 ks</div>
                  <div class="item-name">Jack Daniel´s Honey 0,7 l</div>
                </div>
                <div class="item-price">40$</div>
              </div>
              <!-- Total Price -->
              <div class="total-order-price">
                <span>Total</span>
                <span>80$</span>
              </div>
              <!-- Order comment -->
              <div class="order-comment">
                <span>Comment</span>
                <p class="comment-text">Do not ring the bell</p>
              </div>
            </b-collapse>
          </div>
        </span>
        <span v-else :class="props.column.field">{{
          props.formattedRow[props.column.field]
        }}</span>
      </template>
    </vue-good-table>

    <vue-good-table v-if="selectedItem === 'Products'" class="custom-table-style" :columns="columnsProducts" :rows="rowsProducts">
      <template slot="table-row" slot-scope="props">
        <span :class="props.column.field" v-if="props.column.field == 'order'">
          <table-bullet :number="props.formattedRow[props.column.field]" :value="props.formattedRow[props.column.field]" />
        </span>
        <span v-else-if="props.column.field == 'product'">
          <div :class="props.column.field">
            <img src="https://placekitten.com/300/300" alt="" />
            <span>{{ props.formattedRow[props.column.field] }}</span>
          </div>
        </span>
        <span v-else :class="props.column.field">{{
          props.formattedRow[props.column.field]
        }}</span>
      </template>
    </vue-good-table>

    <vue-good-table v-if="selectedItem === 'Couriers'" class="custom-table-style" :columns="columnsCouriers" :rows="rowsCouriers">
      <template slot="table-row" slot-scope="props">
        <span :class="props.column.field" v-if="props.column.field == 'order'">
          <table-bullet :number="props.formattedRow[props.column.field]" :value="props.formattedRow[props.column.field]" />
        </span>
        <span v-else-if="props.column.field == 'courier'">
          <div :class="props.column.field">
            <img src="https://placekitten.com/300/300" alt="" />
            <span>{{ props.formattedRow[props.column.field] }}</span>
          </div>
        </span>
        <span v-else :class="props.column.field">{{
          props.formattedRow[props.column.field]
        }}</span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { BCollapse, BButton } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import TitleBar from '@/components/TitleBar.vue'
import 'vue-good-table/dist/vue-good-table.css'
import TableBullet from '../../../components/Table/TableBullet.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'AdminStockListing',
  components: {
    TitleBar,
    VueGoodTable,
    TableBullet,
    flatPickr,
    BCollapse,
    BButton,
  },
  data() {
    return {
      selectedItem: 'Orders',
      options: ['Orders', 'Products', 'Couriers'],
      date: null,
      
      // Orders
      columns: [
        {
          label: 'State',
          field: 'state',
          width: '70px',
          sortFn: this.sortFn,
        },
        {
          label: 'Number',
          field: 'number',
          type: 'number',
          formatFn: value => `#${value}`,
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: 'Courier',
          field: 'courier',
          width: '100px',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
            filterDropdownItems: ['Juraj', 'Pedro'],
          },
        },
        {
          label: 'Customer',
          field: 'customer',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: 'District',
          field: 'district',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: 'Created',
          field: 'created',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:ss',
          dateOutputFormat: 'dd.MM.yyyy HH:ss',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: 'Delivered',
          field: 'delivered',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:ss',
          dateOutputFormat: 'dd.MM.yyyy HH:ss',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: 'Duration in min.',
          field: 'duration',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: 'Payment method',
          field: 'payment',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: 'Sum',
          field: 'sum',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          field: 'id',
          sortable: false,
          tdClass: 'collapsible',
          width: '50px',
        },
      ],
      rows: [
        {
          id: 1,
          state: 1,
          number: 123,
          courier: 'Juraj',
          customer: 'Ivan',
          district: '1150',
          created: '24.6.2021 23:35',
          delivered: '24.6.2021 23:55',
          duration: 100,
          payment: 1,
          sum: 80,
        },
        {
          id: 2,
          state: 2,
          number: 321,
          courier: 'Pedro',
          customer: 'Ivan',
          district: '1150',
          created: '24.6.2021 23:35',
          delivered: '24.6.2021 23:55',
          duration: 100,
          payment: 1,
          sum: 80,
        },
      ],

      // Products
      columnsProducts: [
        {
          label: 'Order',
          field: 'order',
          width: '70px',
          sortFn: this.sortFn,
        },
        {
          label: 'Product',
          field: 'product',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: 'Sum',
          field: 'sum',
          type: 'decimal',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: 'Sold',
          field: 'sold',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: '% z obratu',
          field: 'commission',
          type: 'decimal',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: 'Obrat v EUR',
          field: 'profit',
          type: 'decimal',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
      ],
      rowsProducts: [
        {
          id: 1,
          order: 1,
          product: 'Gösser 24er Tray',
          sum: 36.99,
          sold: 100,
          commission: 2,
          profit: 3699
        },
        {
          id: 2,
          order: 2,
          product: 'produkt',
          sum: 80,
          sold: 100,
          commission: 3,
          profit: 1479.6
        },
      ],

      // Couriers
      columnsCouriers: [
        {
          label: 'Order',
          field: 'order',
          width: '70px',
          sortFn: this.sortFn,
        },
        {
          label: 'Courier',
          field: 'courier',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
        {
          label: 'Current cash',
          field: 'cash',
          type: 'decimal',
          filterOptions: {
            enabled: true,
            placeholder: ' ',
          },
        },
      ],
      rowsCouriers: [
        {
          id: 1,
          order: 1,
          courier: 'Juraj',
          cash: 3699
        },
        {
          id: 2,
          order: 2,
          courier: 'Tomáš',
          cash: 2959.2
        },
      ],
    }
  },
  methods: {
    inputChange(value) {
      console.log(value)
    },
    // eslint-disable-next-line no-unused-vars
    sortFn(x, y, col, rowX, rowY) {
      // x - row1 value for column
      // y - row2 value for column
      // col - column being sorted
      // rowX - row object for row1
      // rowY - row object for row2
      // eslint-disable-next-line no-nested-ternary
      return x < y ? -1 : x > y ? 1 : 0
    },
  },
}
</script>
